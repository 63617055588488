<template>
  <div class="widget-custom-in interface" v-if="countryId > 0">
    <template v-for="el in mpkList" :key="el.id">
      <p class="title" v-if="!el.parentId">
        <router-link :to="router(el)">{{ el.name }}</router-link>
      </p>
      <ul v-else>
        <li>
          <router-link :to="router(el)">{{ el.name }}</router-link>
        </li>
      </ul>
    </template>
  </div>
  <div class="buttons-list widget-custom-in contentBetween" v-else>
    <template v-for="el in countryList" :key="el.id">
      <ButtonStock :title="el.text" @click="goTo(el.id)" />
    </template>
  </div>
</template>

<script>
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/widgetElement';

  export default {
    name: 'Widget5',
    components: {
      ButtonStock,
    },
    props: {
      countryId: {
        type: Number,
        default: null,
      },
    },
    watch: {
      countryId: function (/*newValue*/) {
        this.loadMpk();
      },
    },
    data() {
      return {
        countryList: [],
        mpkList: [],
      };
    },
    created() {
      this.loadMpk();
      Utils.loadSelectOptions('countryList', this.countryList);
    },
    methods: {
      goTo(id) {
        this.$router.push({ name: 'Commissions', query: { countryId: id } });
      },
      loadMpk() {
        if (this.countryId > 0) {
          API.getMpk({ countryId: this.countryId }).then((response) => {
            this.mpkList = response.data;
          });
        }
      },
      router(el) {
        var _type = el.parentId > 0 ? 'rg' : 'mp';
        return { name: 'CommissionCard', params: { id: el.id, type: _type, action: 'view' } };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .widget-custom-in.interface {
    max-height: 170px;
  }
  .widget-custom-in .title a {
    font-weight: 500;
  }
  .widget-custom-in a {
    color: #000;
  }
  .widget-custom-in a:hover {
    color: #007bff;
  }
  .buttons-list {
    &:deep(button) {
      &:not(:last-child) {
        margin-right: 20px;
      }
      span {
        display: flex;
        width: max-content;
        word-wrap: normal;
      }
    }
  }
</style>
