import axios from 'axios';

export default {
  getNews: function (request) {
    return axios.get('/api/widget/news', { params: request });
  },
  getTasks: function () {
    return axios.get('/api/widget/tasks');
  },
  getTaskToApprove: function () {
    return axios.get('/api/widget/tasksToApprove');
  },
  getEvents: function (request) {
    return axios.get('/api/widget/eventList', { params: request });
  },
  getEventSimple: function (request) {
    return axios.get('/api/widget/eventSimpleList', { params: request });
  },
  getMpkAssignment: function (request) {
    return axios.get('/api/widget/mpkAssignments', { params: request });
  },
  getEapAssignment: function (request) {
    return axios.get('/api/widget/eapAssignments', { params: request });
  },
  getMpkProject: function (request) {
    return axios.get('/api/widget/projects', { params: request });
  },
  getMpk: function (request) {
    return axios.get('/api/widget/mpkCommissions', { params: request });
  },
  getMassMedia: function (request) {
    return axios.get('/api/massMedia/widget', { params: request });
  },
  getAnaliticYearList: function () {
    return axios.get(`api/foreigntrade/yearList`, { params: { isRussia: true, isProductMode: true } });
  },
  getAnaliticForeignTradeForOne(year, countryId) {
    return axios.get(`api/foreigntrade/summaryForOne/${year}/${countryId}?isRussia=true&isProductMode=true`, {});
  },
  getTotalByFoiv: function (request) {
    return axios.get('/api/mpkAssignment/getTotalByFoiv', { params: request });
  },
};
